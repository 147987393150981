<template>
  <div class="all-content bg-white card-regist">
    <header class="header-wrap">
      <button type="button" class="btn-back" @click="$router.back()"><i class="icon-back"></i></button>
      <p>결제 정보</p>
    </header>
    <div class="content-wrap">
      <div class="main-wrap pb-80">
        <div class="charge-form pt-0">
          <span>
              <button class="btn pay-btn" :class="{'btn-pink' : selectedType === 0, 'btn-gray-white' : selectedType === 1}" @click="setSelectedCardType(0)">개인카드</button>
              <button class="btn pay-btn ml-10" :class="{'btn-pink' : selectedType === 1, 'btn-gray-white' : selectedType === 0}" @click="setSelectedCardType(1)">법인카드</button>
          </span>
          <div class="mt-30">
            <p class="mb-10">이름<span class="color-red" v-show="additionType === '1'">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'이름을 입력해주세요'"
                  :model="name"
                  @update="(_model) => {this.name=_model}"
                  :max-length="16"
                  :input-mode="'text'"
              />
            </div>
          </div>
          <span class="color-red">{{ errors.name }}</span>
          <div class="mt-30">
            <p class="mb-10">전화번호<span class="color-red" v-show="additionType === '1'">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'전화번호를 입력해주세요'"
                  :model="phoneNo"
                  @update="(_model) => {this.phoneNo=_model}"
                  :max-length="11"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
          <span class="color-red">{{ errors.phoneNo }}</span>
          <div class="mt-30">
            <p class="mb-10">카드번호<span class="color-red">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'카드번호를 입력해주세요'"
                  :model="cardNo"
                  @update="(_model) => {this.cardNo=_model}"
                  :max-length="16"
                  :input-mode="'numeric'"
              />
            </div>
          </div>
          <span class="color-red">{{ errors.cardNo }}</span>
          <div class="mt-30 d-flex-row">
            <div class="d-flex-row">
              <div class="w-100">
                <p class="mb-10">유효기간<span class="color-red">*</span></p>
                <div class="select">
                  <RPInputBorder
                      :type="'text'"
                      :placeholder="'MMYY'"
                      :model="expireDate"
                      @update="(_model) => {this.expireDate=_model}"
                      :max-length="4"
                      :input-mode="'numeric'"
                  />
                </div>
                <span class="color-red">{{ errors.expireDate }}</span>
              </div>
            </div>
          </div>
          <div class="mt-30 w-100">
            <div class="d-flex-row">
              <div class="mr-10" v-if="type == 4">
                <p class="mb-10">비밀번호<span class="color-red">*</span></p>
                <div class="select">
                  <RPInputBorder
                      :type="'password'"
                      :placeholder="'비밀번호 앞 2자리'"
                      :model="password"
                      @update="(_model) => {this.password=_model}"
                      :input-mode="'numeric'"
                      :max-length="2"
                  />
                </div>
              </div>
              <div>
                <p class="mb-10 fz-16">할부<span class="color-red">*</span></p>
                <div class="select-wrap">
                  <button class="select" :class="{ 'disabled' : amount < 50000 }" @click="showInstallment">{{ selectedInstallment.name }}</button>
                </div>
              </div>
            </div>
          </div>
          <span class="color-red" v-if="type == 4">{{ errors.password }}</span>
          <div class="mt-30 w-100 mb-10" v-if="type == 4">
            <p class="mb-10">본인확인<span class="color-red">*</span></p>
            <div class="select">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="selectedType === 0 ? '주민번호 앞 6자리를 입력해주세요' : '사업자등록번호 10자리를 입력해주세요'"
                  :model="info"
                  @update="(_model) => {this.info=_model}"
                  :max-length="selectedType === 0 ? 6 : 10"
                  :input-mode="'numeric'"
              />
            </div>
            <span class="color-red">{{ errors.info }}</span>
            <p class="mt-5">
            <span class="color-gray">
              *개인카드 : 주민번호 앞 6자리
              <br/>
              *법인카드 : 사업자번호 10자리
            </span>
            </p>
          </div>
          <div class="mt-30 w-100">
            <p class="mb-10">주소<span class="color-red" v-show="additionType === '1'">*</span></p>
            <div class="d-flex-row">
              <div class="select-disable no-right-border">
                <RPInputBorder
                    :type="'text'"
                    :placeholder="'주소'"
                    :model="address"
                    disable="true"
                    @update="(_model) => {this.address=_model}"
                    :input-mode="'text'"
                />
              </div>
              <button class="input-address btn-dgray font-16" style="align-self: center" @click="searchAddress">
                검색
              </button>
            </div>
            <span class="color-red">{{ errors.address }}</span>
            <div class="select mt-10">
              <RPInputBorder
                  :type="'text'"
                  :placeholder="'상세주소'"
                  :model="addressDetail"
                  @update="(_model) => {this.addressDetail=_model}"
                  :input-mode="'text'"
              />
            </div>
            <span class="color-red">{{ errors.addressDetail }}</span>
          </div>
        </div>
      </div>
      <button class="align-bottom btn btn-m btn-pink" @click="doPay">결제</button>
    </div>
    <s-m-s-pay-select
        v-show="showSelect"
        :installmentList="installmentList"
        @selectedInstallment="setSelectedInstallment"
        @close="showSelect=false"/>
  </div>
</template>

<script>
import RPInputBorder from "@/components/common/RPInputBorder";
import SMSPaySelect from "@/components/smsSentPayment/SMSPaySelect";

export default {
  name: "SMSUserPayCard",

  components: {
    RPInputBorder,
    SMSPaySelect
  },
  data() {
    return {
      additionType: this.$route.params.additionType,
      amount: this.$route.params.amount,
      token: this.$route.params.token,
      type: this.$route.params.type,
      selectedType: 0,  // 0: 개인, 1: 법인
      name: '',
      cardNo: '',
      expireDate: '',
      info: '',
      password: '',
      phoneNo: '',
      errors: {
        cardNo: '',
        expireDate: '',
        password: '',
        info: '',
        name: '',
        phoneNo: '',
        address: '',
        addressDetail: '',
      },
      showSelect: false,
      address: '',
      addressDetail: '',
      selectedInstallment: {
        'installment': '00',
        'name': '일시불'
      },
      installmentList: [
        {
          'installment': '00',
          'name': '일시불'
        },
        {
          'installment': '02',
          'name': '2개월'
        },
        {
          'installment': '03',
          'name': '3개월'
        },
        {
          'installment': '04',
          'name': '4개월'
        },
        {
          'installment': '05',
          'name': '5개월'
        },
        {
          'installment': '06',
          'name': '6개월'
        },
        {
          'installment': '07',
          'name': '7개월'
        },
        {
          'installment': '08',
          'name': '8개월'
        },
        {
          'installment': '09',
          'name': '9개월'
        },
        {
          'installment': '10',
          'name': '10개월'
        },
        {
          'installment': '11',
          'name': '11개월'
        },
        {
          'installment': '12',
          'name': '12개월'
        },
      ]
    }
  },

  methods: {
    searchAddress() {
      new window.daum.Postcode({
        oncomplete: (data) => {
          console.log('postdata', data)
          let fullAddr = data.address; // 최종 주소 변수
          let extraAddr = ''; // 조합형 주소 변수

          // 기본 주소가 도로명 타입일때 조합한다.
          if (data.addressType === 'R') {
            //법정동명이 있을 경우 추가한다.
            if (data.bname !== '') {
              extraAddr += data.bname;
            }
            // 건물명이 있을 경우 추가한다.
            if (data.buildingName !== '') {
              extraAddr += (extraAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 조합형주소의 유무에 따라 양쪽에 괄호를 추가하여 최종 주소를 만든다.
            fullAddr += (extraAddr !== '' ? ' (' + extraAddr + ')' : '');
          }

          this.address = fullAddr;
        }
      }).open()
    },

    showInstallment(){
      if (this.amount >= 50000){
        this.showSelect = true
      }
    },

    setSelectedInstallment(installment) {
      this.selectedInstallment = installment
      this.showSelect = false
      console.log(this.selectedInstallment)
    },

    setSelectedCardType(_selectedType){
      this.selectedType = _selectedType
    },

    doPay() {
      if (this.additionType === '1' && this.name.length === 0) {
        this.errors.name = '이름을 입력해주세요.'
        return
      }
      if (this.additionType === '1' && this.phoneNo.length !== 11) {
        this.errors.phoneNo = '전화번호를 정확하게 입력해주세요.'
        return
      }
      if (this.cardNo.length !== 16) {
        this.errors.cardNo = '카드 번호를 정확하게 입력해주세요.'
        return
      }
      if (this.expireDate.length !== 4) {
        this.errors.expireDate = '유효기간을 정확하게 입력해주세요.'
        return
      }
      if (this.password.length !== 2 && this.type == 4) {
        this.errors.password = '비밀번호를 정확하게 입력해주세요.'
        return
      }
      if (this.selectedType === 0 && this.info.length !== 6 && this.type == 4) {
        this.errors.info = '생년월일을 정확하게 입력해주세요.'
        return
      }
      if (this.selectedType === 1 && this.info.length !== 10 && this.type == 4) {
        this.errors.info = '사업자번호를 정확하게 입력해주세요.'
        return
      }
      if (this.additionType === '1' && this.address.length === 0) {
        this.errors.address = '주소를 입력해주세요.'
        return
      }
      if (this.additionType === '1' && this.addressDetail.length === 0) {
        this.errors.addressDetail = '상세주소를 입력해주세요.'
        return
      }

      let formData = new FormData()
    // 비인증 cardNumber, cardPass 안받음
      formData.append('token', this.token)
      formData.append('cardNumber', this.cardNo.replaceAll('-',''))
      formData.append('cardType', this.selectedType.toString())
      formData.append('expireMm', this.expireDate.substr(0, 2))
      formData.append('expireYy', this.expireDate.substr(2, 2))
      formData.append('installment', this.selectedInstallment.installment)
      if(this.type == 4) {
        formData.append('cardPassword', this.password)
        formData.append('identification', this.info.toString())
      }

      if(this.additionType === '1') {
        formData.append('additionName', this.name)
        formData.append('additionPhone', this.phoneNo)
        formData.append('additionAddress', this.address)
        formData.append('additionAddressDetail', this.addressDetail)
      }

      this.$post(this.$DOMAIN, this.$PAYMENT_SMS, 'post_payment_sms', formData, (result) => {
        console.log(result)
        this.$router.replace({ name: 'SMSUserPaySuccess', params: { amount: this.amount }})
      }, (result) => {
        this.httpError(result.data)
        this.$router.replace({ name: 'SMSUserPayFail', params: { amount: this.amount , message : result.data.message}})
      })
    }
  },
}
</script>

<style scoped>

</style>
