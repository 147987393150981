<template>
  <div class="all-content sms-pay-success">
    <div class="content-wrap bg-white content-row" style="height: 100%; top: 0">
      <div class="confirm-list regist-card-success">
        <p style="justify-content: flex-end">
          <img src="/image/character_hat_sad.svg" alt="캐릭터">
        </p>
        <p class="comment">
          <em class="t-c black-title">{{ amount | numberWithCommas }}원</em>
          <em class="t-c black-title mt-0">결제 실패</em>
          <em class="t-c mt-10">결제가 실패되었습니다.<br/>다시 한번 시도하세요.</em>
          <em v-if="message" class="t-c mt-10" style="color: darkred; font-weight: bold;">
            {{ message }}
          </em>
        </p>
        <div class="w-90">
          <p class="info mt-10"></p>
          <p class="info mt-10">
            <span class="title">결제일시</span>
            <span class="cardInfo">{{ new Date() | moment('YY.MM.DD HH:mm:ss') }}</span>
          </p>
        </div>
      </div>
    </div>
<!--    <div class="d-flex-row">-->
<!--      <button class="btn-pink mr-auto btn-m" @click="$router.push({ name: 'SMSUserPayInfo' })">다시결제</button>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "SMSUserPayFail",

  data() {
    return {
      amount: this.$route.params.amount,
      message: this.$route.params.message,
    }
  },
}
</script>

<style scoped>

</style>
